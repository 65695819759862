<template>
  <img id="logo" alt="nymba" src="./assets/nymba logo.svg">
  <div id="video-control-wrapper">
    <div id="video-controls">
    <!-- <div class="white-space">YoYoooo0000o</div> -->
      <VideoControl v-for="control in videoControls" :key="control.text" :length="control.length" :text="control.text" :id="control.order" @next="rewindToNext"  @click="clear(control.order); rewindVideo(control.rewind)"/>
    <!-- <div class="white-space">YoYooo0000oo</div> -->
    </div>
  </div>
  <video id="feature-video" muted autoplay loop playsinline>
    <source src="./assets/video.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  <div id="about">
    <div id="wordmark">Nymba</div>
    <div id="title">Imagination to image</div>
  </div>
  <a href="https://saf5q64i739.typeform.com/to/cqDXh0uF" id="signup">
    <button>Sign up for beta</button>
  </a>
  <div id="copyright">©2024 Nymba</div>
</template>

<script>
import VideoControl from './components/VideoControl.vue'
import EventBus from './eventBus'

export default {
  name: 'App',
  components: {
    VideoControl
  },
  data: function() {
    return { videoControls: [
      { length: 16700, rewind: 100, text: 'Generate image boards', order: 0 },
      { length: 14500, rewind: 1100, text: 'Change any object', order: 1 },
      { length: 13500, rewind: 1900, text: 'Blend any image', order: 2 },
    ]
  }},
  methods: {
    rewindVideo(frameNumber) {

      const allProgressElements = document.querySelectorAll('.progress');
      const allControlTextElements = document.querySelectorAll('.video-control-text');
    
      allProgressElements.forEach(progressElement => {
        const computedStyle = window.getComputedStyle(progressElement);
        if (computedStyle.width !== '0px') {
          progressElement.style.transition = 'none';
          progressElement.style.width = '0%';
        }
      });

      allControlTextElements.forEach(textElement => {
        const computedStyle = window.getComputedStyle(textElement);
        if (computedStyle.width !== '0px') {
          textElement.style.transition = 'none';
          textElement.style.color = '#ffffff70';
        }
      });

      const video = document.getElementById('feature-video');
      if (video) {
        const frameRate = 60; // Assuming the video is 25 FPS
        const timeInSeconds = frameNumber / frameRate;
        video.currentTime = timeInSeconds;

      }
    },
    rewindToNext(id) {
      
      const currentIndex = this.videoControls.findIndex(control => control.order === id);
      const nextIndex = (currentIndex + 1) % this.videoControls.length;
      const nextControl = this.videoControls[nextIndex];
      this.rewindVideo(nextControl.rewind);

      const videoControls = document.querySelectorAll('.video-control');
      const nControl = videoControls[nextIndex];

      if (nextControl) {
        nControl.click();
      }

      // console.log(nControl, nextControl)
    },
    clear(id) {
      EventBus.emit('clearing', id);
      // console.log(EventBus)
    }
}
}
</script>

<style>



* {
  background-color: none;
  color: #ffffff;
  font-size: 16px;
  font-family: Basis Grotesque Pro Medium, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  white-space: nowrap;
  transition: all 0.15s cubic-bezier(0, 0, 0.5, 0);
}

html {
  background-color: #000000;
}

button {
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-family: Basis Grotesque Pro Medium, Avenir, Helvetica, Arial, sans-serif;
}

button:hover {
  background-color: #ffffffce;
}

.white-space {
  visibility: visible;
  width: 200px;
  display: block;
  height: 1px;
  background-color: transparent; /* Ensure background is transparent */
}

#logo {
  width: 25px;
  height: 25px;
  top: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

#about {
  position: absolute;
  bottom: 40px;
  left: 100px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}

#two-dots {
  opacity: 0.5;
}

#wordmark, #two-dots {
  padding-right: 10px;
}

#wordmark {
  visibility: hidden;
}


#video-controls {
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: fit-content;
  width: fit-content;
}

#video-control-wrapper {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;  
  overflow-x: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}

#signup {
  position: absolute;
  right: 20px;
  bottom: 30px;
}

#feature-video {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 750px;
}

#copyright {
  opacity: 50%;
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: fit-content;
  /* visibility: hidden; */
}

@media only screen and (max-width: 1200px) {

  #video-control-wrapper {
    bottom: 120px;
  }

}

@media only screen and (max-width: 720px) {

  * {
    font-size: 14px;
  }

  .white-space {
    visibility: visible;
    width: 300px;
    display: block;
  }

  #copyright {
    visibility: visible;
  }

  #video-controls {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    display: flex;
    justify-content: left;
    align-items: left;
    
    /* padding-left: 30px; */
    /* background-color: blue; */
  }

  #video-control-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  #video-controls::-webkit-scrollbar {
      display: none;
  }

  #video-controls {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  #about {
    position: static;
  }

  #wordmark, #title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    text-align: center;
    width: fit-content;
    padding: 0px;

  }

  #wordmark {
    top: 50px;
    font-size: 14px;
    visibility: visible;
  }

  #title {
    top: 80px;
    width: 100%;
  }

  #two-dots {
    visibility: hidden;
  }

  #signup {
    position: absolute;
    width: fit-content;
    right: 50%;
    left: 50%;
    bottom: 50px;
    transform: translateX(-50%);
    width: fit-content;
  }

  #feature-video {
    width: 90%;
    left: 50%;
    right: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  #copyright {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 400px) {
  #video-control-wrapper {
    width: 100%;  
  }

}



</style>
