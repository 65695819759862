<template>
  <div class="video-control">
    <div class="line">
      <div class="progress"></div>
    </div>
    <div class="video-control-text">{{ text }}</div>
  </div>
</template>

<script>
import EventBus from '../eventBus'

export default {
  name: 'VideoControl',
  props: {
    text: String,
    length: Number,
    id: Number,
  },
  data: function() {
    return { 
      interval: null,
      active: null,
  }},
  methods: {
    expandProgress() {

      const progressElement = this.$el.querySelector('.progress');
      const videoControlText = this.$el.querySelector('.video-control-text');
      if (progressElement) {
        setTimeout(() => {
          videoControlText.style.color = 'white';
        }, 1);
        progressElement.style.transition = `width ${this.length}ms`;
        progressElement.style.width = '100%';

        this.interval = setTimeout(() => {
            this.shrinkProgress();
            
            this.$emit('next', this.id);

        }, this.length);

      }
    },
    shrinkProgress() {
      const videoControlText = this.$el.querySelector('.video-control-text');
      const progressElement = this.$el.querySelector('.progress');
      if (progressElement) {
        videoControlText.style.color = '#ffffff70';
        progressElement.style.transition = 'width 10ms';
        progressElement.style.width = '0%';
      }
    },
    clearTimer(id) {
      clearTimeout(this.interval);
      this.interval = null
      console.log('clear ' + id)
      this.shrinkProgress()

      if(id == this.id) {
        // this.$el.click();
        this.expandProgress()
      }

      // if (this.interval != null) {
      //   clearTimeout(this.interval);
      // }
      // this.interval = null
      // this.active = false
      // this.active = id
      // clearTimeout(this.interval);
    }
  },
  mounted() {
    EventBus.on('clearing', this.clearTimer);
    if (this.id == 0) {
      this.$el.click();
      this.expandProgress()
    }
    // console.log(EventBus)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* .video-control {
} */


* {
  white-space: normal;
  cursor: pointer;

  align-items: flex-start;
  display: block;
  white-space: nowrap;
  margin-top: 6px;

  color: #ffffff70;

  transition: none;
  overflow: visible;
}

.video-control {
  height: 40px;
  margin-right: 5px;
  margin-left: 5px;
  width: fit-content;
}

.video-control:hover {
  opacity: 0.75;
}

.video-control-text {
  background: none;
  height: 100px;
  white-space: nowrap;
}

.line {
  background-color: #4a4949 !important;
  width: 100%;
  height: 1px;
  display: block;
  content: " ";
}

.progress {
  background-color: white;
  height: 1px;
  display: block;
  width: 0%;
  transition: all 1s linear;
}




@media only screen and (max-width: 720px) {

  .video-control {
    height: 40px;
    box-sizing: border-box;
    width: fit-content;
    padding: 0px;
    flex: 0 0 auto;
}


}

</style>
